
.tox-tbtn {
  background-color: #F2EAFE !important;
}

.tox-tbtn:hover {
  background-color: #D0C1FF !important;
  border-color: #BBAAFF !important;
}

.tox .tox-tbtn svg {
  fill: #6C757D !important;
}

.tox-toolbar__primary{
  background-color: #F2EAFE !important;
}
.line-clamp-1{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  width: 200px;
  height: 38px;
}
/* .tox .tox-notification--warn, .tox .tox-notification--warning {
  display: none!important;
  height: 0!important;
    width: 0!important;
    overflow: hidden!important;
    padding: 0!important;
}
html{
  overflow: hidden!important;
} */