
.pagination {
    display: flex;
    list-style: none;
    padding: 10px 10px;
}

.paginations {
    display: flex;
    list-style: none;
    padding: 10px 10px;
    margin-right: 50px;
}


.page, .previous, .next, .break {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.12);
    // border-radius: 6px;
    color: #524D4D;
    // margin: 0 4px;
    font-size: 16px;
    font-weight: 400;
    &.active {
        color: #fff;
        box-shadow: 0px 0px 3px 0px inset #A873F5;
        background-color: #A873F5;
    }
}

.page, .break {
    width: 38px;
    height: 38px;
}
.previous, .next {
    padding: 0 10px;
}
.previous {
    margin-left: 0;
    // margin-right: 10px;
}
.next {
    margin-right: 0;
    // margin-left: 10px;
}