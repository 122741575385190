.listCont {
    width: 450px;
}

.active {
    border-color: var(--chakra-colors-gray-900) !important;   
    color: var(--chakra-colors-gray-900) !important;   
    background-color: #faf9ff;
}

.chatDetailsCont {
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 520px;
    position: relative;
}

.pagination {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background-color: #FFF;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.listContInner {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 47px;
}


.listTab {
    border-bottom: 0;
    padding: 12px 20px;
    cursor: pointer;
    z-index: 9;
    position: relative;
    margin-right: -1px;
    background-color: #FFF;
    transition: color 0.2s ease-in-out;
    &:hover {
        color: var(--chakra-colors-gray-900);
    }
}
.messageStart {
    font-size: 14px;
    font-weight: 500;
    color: var(--chakra-colors-gray-600);
}
.messageTime {
    font-size: 12px;
    color: var(--chakra-colors-gray-500);
    margin-bottom: 6px;
}
.activeList {
    color: var(--chakra-colors-gray-900) !important;   
    background-color: #faf9ff;
    border-right: 3px solid #A873F5;
}

.detailsQuestion {
    background-color: var(--chakra-colors-gray-100) !important;   ;
}

.detailsAnswer {
    background-color: var(--chakra-colors-blue-50) !important;   ;
}

.detailsItem {
    padding: 10px;
    border-radius: 6px;
    line-height: 1.6;
    min-height: 42px;
    margin-bottom: 12px;
    font-size: 14px;
}
.detailsItemInner {
    
    display: inline-block;
}

.loadingCont {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 999;
}

.trainingDataCont {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
}
