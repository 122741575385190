.tableHd th{
    font-size: 16px;
    color: #212529;
  }

.tableTd td{
    font-size: 14px;
    color: #6C757D;
  }

  .projectCount{
    padding: 4.2px 7.8px;
    border-radius: 100px;
    background-color: #ff000020;
    color: red;
    font-size: 12px;
  }
  th{
    font-size: 16px!important;
    text-transform: capitalize!important;
    font-weight: 500!important;
  }
  td{
    font-size: 16px!important;
  }