.cancaleBtn:hover{
  background-color: #5C636A !important;
}
th{
  font-weight: 700 !important;
  font-size: 16px!important;
  text-transform: capitalize!important;
  color: #212529 !important;
}
.chip{
  background: #9800ff!important;
}

