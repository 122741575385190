.tableHd th{
  font-size: 16px;
  color: #212529;
  text-transform: initial;
}

.tableTd td{
  font-size: 14px;
  color: #6C757D;
}


.readBtn{
  // border: none !important;
  // background: transparent !important;
}

.tableTr th {
  font-size: 16px;
  color: #212529;
  text-transform: initial;
}

.tableTr td{
  font-size: 14px;
  color: #6C757D;
}

