.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    
    
    /* gray/900 */
    
    color: #171923;
    margin-bottom: 8px;
}

.subHeading {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    
    
    /* gray/500 */
    
    color: #718096;
}

.headingSm {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    /* gray/700 */

    color: #2D3748;
    margin-bottom: 8px;
}