.cancaleBtn:hover{
  background-color: #5C636A !important;
}
.tableHd Th{
  font-size: 16px!important;
  color: #212529;
  font-weight: 700 !important;
}
.chip{
  background: #9800ff!important;
}
.Completed{
  padding: 4.2px 7.8px;
  border-radius: 100px;
  background-color: #1987541A;
  color: #198754;
  font-size: 12px;
  font-weight: 500;
}
.Running{
  padding: 4.2px 7.8px;
  border-radius: 100px;
  background-color: #DBEAFE;
  color: #1E40AF;
  font-size: 12px;
  font-weight: 500;
}
.Schedule{
  padding: 4.2px 7.8px;
  border-radius: 100px;
  background-color: #FEF9C3;
  color: #854D0E;
  font-size: 12px;
  font-weight: 500;
}
.initiated{
  padding: 4.2px 7.8px;
  border-radius: 100px;
  color: #CA6510;
  background: #FD7E141A;
  font-size: 12px;
  font-weight: 500;
}

.paused{
  padding: 4.2px 7.8px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 500;
  color: #ca1010;
  background: rgb(253 20 20 / 10%);
}
.modalTextHeding{
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 10px;
}


.modalTextPara{
  font-size: 14px;
  font-weight: 400;
  color: #6C757D;
}


.newCampaigHeding{
  font-size: 16px;
  font-weight: 700;
  color: #1A202C;
}


.newCampaigPara{
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #212529;
}

.questionDeleteBtn {
  position: absolute !important;
  left: 0;
  z-index: 9;
}
